import { Box, Chip, Fade } from '@mui/material';
import dayjs from 'dayjs';
import { useServer } from 'hooks/useServer';
import { MRT_ColumnDef } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { TecnoTable } from 'sections/table/tecno-table';
import { dispatch, useSelector } from 'store';
import { refetchErrors } from 'store/reducers/helpers';
import { CountedData } from 'types/counted-data';
import { FilterProps, SortingProps } from 'types/dataToSend';
import { ErrorDto } from 'types/dto/errors.dto';
import { formatFilters, formatSorting } from 'utils/tableHelpers';

const ExternalErrors = () => {
  const location = useLocation();
  const helpers = useSelector((state) => state.helpers);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingProps[]>([]);
  const [filters, setFilters] = useState<FilterProps[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 200
  });
  const [dataToSend, setDataToSend] = useState<{
    skip: number;
    take: number;
    orderBy?: string;
    filterBy?: string;
    search?: string;
  }>({ skip: pagination.pageIndex * pagination.pageSize, take: pagination.pageSize });

  const [tableData, setTableData] = useState<CountedData<ErrorDto>>();
  const getErrors = useServer<CountedData<ErrorDto>>('get', '/apps/externals', 'all-error', dataToSend, true);

  useEffect(() => {
    if (getErrors.data?.data) {
      setTableData(getErrors.data.data);
    }
  }, [getErrors.data?.data]);

  useEffect(() => {
    getErrors.refetch().then();
    if (helpers.refetchErrors) {
      dispatch(refetchErrors(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToSend, helpers.refetchErrors]);

  useEffect(() => {
    setDataToSend({
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      orderBy: formatSorting.code(sorting),
      filterBy: formatFilters.code(filters, {
        equals: ['id', 'develop', 'userId', 'userIp'],
        contains: ['userName', 'userUrl', 'source']
      }),
      search: globalFilter || undefined
    });
  }, [pagination, sorting, filters, globalFilter]);

  const columns = useMemo<MRT_ColumnDef<ErrorDto>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 120
      },
      {
        id: 'app',
        accessorKey: 'app.name',
        header: 'PROGETTO',
        enableColumnFilter: true,
        maxSize: 120,
        filterVariant: 'select',
        filterSelectOptions: [
          {
            text: 'Babonbo Client',
            value: 'Babonbo Client'
          }
        ]
      },
      {
        accessorKey: 'develop',
        header: 'PROD/DEV',
        size: 50,
        Cell: ({ cell }) => (
          <Box sx={{ display: 'flex' }} maxWidth={50} marginX={'auto'} justifyContent={'center'}>
            <Chip variant="light" size="small" color={cell.getValue() ? 'info' : 'error'} label={`${cell.getValue() ? 'DEV' : 'PROD'}`} />
          </Box>
        ),
        filterVariant: 'select',
        filterSelectOptions: [
          {
            text: 'Develop',
            value: 'true'
          },
          {
            text: 'Produzione',
            value: 'false'
          }
        ]
      },
      {
        id: 'at',
        accessorFn: (row) => dayjs(row.at).format('DD/MM/YYYY HH:mm'),
        header: 'DATA',
        enableColumnFilter: false,
        size: 150,
        muiTableBodyCellProps: {
          sx: {
            maxWidth: 150
          }
        }
      },
      {
        id: 'message',
        accessorFn: (row) => row.message ?? 'ND',
        header: 'Messaggio',
        muiTableBodyCellProps: { sx: { 'word-wrap': 'break-word', 'white-space': 'pre-wrap' } },
        maxSize: 30
      },
      {
        id: 'url',
        accessorFn: (row) => row.userUrl ?? 'Non registrato',
        header: 'Messaggio',
        muiTableBodyCellProps: {
          sx: {
            maxWidth: 40
          }
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, tableData]
  );

  return (
    <>
      {location.pathname === '/external-errors' && (
        <Fade in>
          <Box>
            <TecnoTable
              name="Errori"
              countedData={tableData}
              columns={columns}
              pagination={pagination}
              onPaginationChange={setPagination}
              sorting={sorting}
              onSortingChange={setSorting}
              filters={filters}
              onFiltersChange={setFilters}
              globalFilter={globalFilter}
              onGlobalFilterChange={setGlobalFilter}
              refetch={() => getErrors.refetch()}
              isLoading={getErrors.isFetching}
              onShareAction={() => {}}
            />
          </Box>
        </Fade>
      )}
      <Outlet />
    </>
  );
};

export default ExternalErrors;
