import { Button, Grid, Typography } from '@mui/material';
import { useServer } from 'hooks/useServer';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LogDto } from 'types/dto/log.dto';
import { formatFilters } from 'utils/tableHelpers';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import MainCard from 'components/MainCard';
import { CustomWidthTooltip } from 'components/custom/CustomWidthTooltip';
import { CustomSimplebar } from 'components/simplebar';

const LogGtv = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [dataToSend] = useState<{
    skip: number;
    take: number;
    orderBy?: string;
    filterBy?: string;
    search?: string;
  }>({
    skip: 0,
    take: 1,
    filterBy: formatFilters.code([{ id: 'id', value: id }], {
      equals: ['id']
    })
  });
  const { data, refetch, isFetching } = useServer<LogDto>('get', `/apps/log/${id}`, `app-log-${id ?? 0}`, dataToSend);
  console.log('data', data);
  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [data]);

  const goBack = () => {
    navigate('/gtv-errors');
  };

  if (!id) {
    return <h1>Log non presente</h1>;
  } else {
    if (isFetching) {
      return <LoadingOutlined label={'Loading'} />;
    } else {
      if (data?.data && id) {
        const log = data.data;
        const localStorage = log?.localStorage ? JSON.parse(log?.localStorage) : {};
        return (
          <>
            <Button variant="contained" onClick={goBack} sx={{ ml: 5, mt: 5 }}>
              INDIETRO
            </Button>
            <CustomSimplebar>
              <MainCard
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: 5,
                  py: 10,
                  width: '95%',

                  borderRadius: '12px'
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      ID: {log.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Data: {dayjs(log.at).format('DD-MM-YYYY HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Modello: {localStorage?.deviceInfo?.modello || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Agent: {localStorage?.deviceInfo?.modello || (localStorage?.firebase?.userId.length > 20 ? 'IPhone' : 'Android')}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      VERSION: {localStorage?.settings?.['appVersion'] || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      User ID: {localStorage.firebase.userId || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      IP: {log.userIp}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      Message: {log?.message ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      Stack: {log?.stack ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" maxHeight={240}>
                    <CustomWidthTooltip title={JSON.stringify(log.body)}>
                      <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                        Body: {JSON.stringify(log.body)}
                      </Typography>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      SessionStorage: {log?.sessionStorage ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      LocalStorage: {log?.localStorage ?? 'Non definito'}
                    </Typography>
                  </Grid>
                </Grid>
              </MainCard>
            </CustomSimplebar>
          </>
        );
      } else {
        return <h1>Log non trovato!</h1>;
      }
    }
  }
};

export default LogGtv;
