import { Button, Grid, Typography } from '@mui/material';
import { useServer } from 'hooks/useServer';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LogDto } from 'types/dto/log.dto';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import MainCard from 'components/MainCard';
import { CustomWidthTooltip } from 'components/custom/CustomWidthTooltip';
import { CustomSimplebar } from 'components/simplebar';

const LogExternal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch, isFetching } = useServer<LogDto>('get', `/apps/log/${id}`, `app-log-${id ?? 0}`);
  useEffect(() => {
    if (!isFetching && !data) {
      refetch();
    }
  }, [data]);

  const goBack = () => {
    navigate('/external-errors');
  };

  if (!id) {
    return <h1>Log non presente</h1>;
  } else {
    if (isFetching) {
      return <LoadingOutlined label={'Loading'} />;
    } else {
      if (data?.data && id) {
        const log = data.data;

        return (
          <>
            <Button variant="contained" onClick={goBack} sx={{ ml: 5, mt: 5 }}>
              INDIETRO
            </Button>
            <CustomSimplebar>
              <MainCard
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: 5,
                  py: 10,
                  width: '95%',

                  borderRadius: '12px'
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      ID: {log.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Data: {dayjs(log.at).format('DD-MM-YYYY HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Method: {log.method}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      ENV: {log?.env || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      VERSION: {log?.version || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      PATH: {log?.path || 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5}>
                      Error code: {log.errorCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      Query: {JSON.stringify(log.query)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      Params: {JSON.stringify(log.params)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      User: {log.user ? `${log?.user?.firstname} ${log?.user?.lastname}` : 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      Auth: {log.hasApiKey ? 'Api Key' : log.hasBearerToken ? 'Token' : 'NO AUTH'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      IP: {log.requestIp}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      TEST IP: {log.testIp ?? 'ND'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3" my={1.5} noWrap={false}>
                      Agent: {log.agent}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      Message: {log?.message ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      Stack: {log?.stack ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      ComponentStack: {log?.componentStack ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" maxHeight={240}>
                    <CustomWidthTooltip title={JSON.stringify(log.body)}>
                      <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                        Body: {JSON.stringify(log.body)}
                      </Typography>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      SessionStorage: {log?.sessionStorage ?? 'Non definito'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" my={1.5} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} noWrap>
                      LocalStorage: {log?.localStorage ?? 'Non definito'}
                    </Typography>
                  </Grid>
                </Grid>
              </MainCard>
            </CustomSimplebar>
          </>
        );
      } else {
        return <h1>Log non trovato!</h1>;
      }
    }
  }
};

export default LogExternal;
